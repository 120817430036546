import React from "react";
import "./NoData.css";

const NoData = () => {
  return (
    <div>
      <h3 className="noData">
        You do not have any suggested rate changes awaiting approval at present
      </h3>
    </div>
  );
};

export default NoData;
