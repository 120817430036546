import React from "react";
import MainMenu from "../Menu/Menu";
import { useCookies } from "react-cookie";
import YieldTable from "../Yield-Table/yieldTable";

const Dashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  return (
    <div>
      <MainMenu />
      <YieldTable />
    </div>
  );
};

export default Dashboard;
