import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Store/Slice/userSlice";
import hotelSlice from "./Store/Slice/hotelSlice";
import hotelCompset from "./Store/Slice/hotelCompset";

export const store = configureStore({
  reducer: {
    compSet: hotelCompset,
    userData: userSlice,
    hotelDataId: hotelSlice,
  },
});
