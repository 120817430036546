import moment from "moment";

const otaDataFunction = async (data: any, dates: any) => {
  const obj: any[] = [];
  console.log("data", data, "date", dates);

  dates.forEach((element: any) => {
    const momentDate = moment.utc(element + " 12:00").valueOf();

    obj.push(data[momentDate]);
  });

  return obj;
};

const otaDemandFunction = async (data: any, dates: any) => {
  const obj: any[] = [];

  dates.forEach((element: any) => {
    const momentDate = moment.utc(element + " 12:00").valueOf();

    obj.push(data[momentDate]);
  });

  const demand = obj.map((data: any) => data.demand);

  return demand;
};

export { otaDataFunction, otaDemandFunction };
