import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const ProtectRoute = (props: any) => {
  const [cookies] = useCookies(["token"]);
  const navigate = useNavigate();
  const { Component } = props;

  useEffect(() => {
    const cookie = cookies.token;
    if (!cookie) {
      navigate("/signIn");
    }
  });
  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectRoute;
