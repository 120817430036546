import React, { useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addUser } from "../../Redux/Store/Slice/userSlice";

async function custom_header(): Promise<object> {
  return {
    Authorization: await getJwtToken(),
  };
}

async function getJwtToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

const SignInButton = styled(LoadingButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: "var(--button-color)",
  marginTop: "2vh",
  marginBottom: "2px",
  "&:hover": {
    backgroundColor: "var(--button-color)",
  },
}));

const theme = createTheme();

// Main SignIn Function called

const SignIn = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(["token", "idToken", "session"]);
  const navigate = useNavigate();

  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true);
    login(data.get("email"), data.get("password"));
  };

  async function login(username: any, password: any) {
    try {
      const user = await Auth.signIn(username, password);

      dispatch(addUser(user));

      if (user.challengeName == "NEW_PASSWORD_REQUIRED") {
        setLoading(false);
        navigate("/requestNewPassword");
      }
      if (user.signInUserSession.accessToken.jwtToken) {
        setLoading(false);
        navigate("/");
      }
      setCookie("token", user.signInUserSession.accessToken.jwtToken, {
        path: "/",
      });
      setCookie("idToken", user.signInUserSession.idToken.jwtToken, {
        path: "/",
      });
    } catch (error) {
      console.log(error);
      if (
        error ==
        "PasswordResetRequiredException: Password reset required for the user"
      ) {
        navigate("/resetPassword");
      }
      if (error == "NotAuthorizedException: Incorrect username or password.") {
        toast.error("Incorrect username or password", {
          theme: "colored",
        });
      }
      if (error == "UserNotFoundException: User does not exist.") {
        toast.error("User does not exist", {
          theme: "colored",
        });
      }
      if (
        error ==
        "NotAuthorizedException: Temporary password has expired and must be reset by an administrator."
      ) {
        toast.error(
          "Temporary password has expired and must be reset by an administrator",
          {
            theme: "colored",
          }
        );
      }
      setLoading(false);
    }
  }

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    setInput({ ...input, [name]: value });
    switch (name) {
      case "email":
        if (!value) {
          console.log("no value");
          setEmailError(true);
        } else {
          console.log("value");
          setEmailError(false);
        }

        break;

      case "password":
        if (!value) {
          console.log("no value");
          setPassError(true);
        } else {
          console.log("value");
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };

  const handleOnBlur = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "email":
        if (!value) {
          console.log("no value");
          setEmailError(true);
        } else {
          console.log("value");
          setEmailError(false);
        }

        break;

      case "password":
        if (!value) {
          console.log("no value");
          setPassError(true);
        } else {
          console.log("value");
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img src="/assets/revanista-logo.png" alt="revanista" />
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleOnChange}
              onBlur={(e) => handleOnBlur(e)}
              error={emailError}
              helperText={emailError ? "Please fill email field" : ""}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleOnChange}
              onBlur={(e) => handleOnBlur(e)}
              error={passError}
              helperText={passError ? "Please fill password field" : ""}
            />

            <SignInButton
              loading={loading ? true : false}
              type="submit"
              variant="contained"
              fullWidth
            >
              Sign In
            </SignInButton>

            <Grid container>
              <Grid item xs style={{ textAlign: "center", marginTop: "2vh" }}>
                <Link to="/forgetPassword">Forgot password?</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
