import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { HashRouter } from "react-router-dom";
import "./index.css";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { store } from "./Redux/store";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    <HashRouter>
      <CssBaseline />
      <CookiesProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </CookiesProvider>
    </HashRouter>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
  </ThemeProvider>
);
